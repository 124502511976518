













import Vue from 'vue'

import JQueryChip from '@/components/controls/JQueryChip.vue'

export default Vue.extend({
  components: {
    JQueryChip,
  },
  props: {
    query: Object,
  },
})
