



































import Vue from 'vue'

import JTable from '@/components/controls/JTable.vue'
import JSelect from '@/components/controls/JSelect.vue'

import { TableSearchItem } from '@/utils/TableSearchItem'

export default Vue.extend({
  components: {
    JTable,
    JSelect,
  },
  props: {
    fields: Array,
  },
  data: () => ({
    items: [] as TableSearchItem[],

    headers: [
      { text: 'Field', value: 'field' },
      { text: 'Operator', value: 'operator', width: '3em' },
      { text: 'Value', value: 'value' },
    ],

    operators: [
      { id: '=' },
      { id: '<>' },
      { id: '>' },
      { id: '<' },
      { id: '>=' },
      { id: '<=' },
    ],
  }),
  methods: {
    create(): void {
      this.items.push(new TableSearchItem())
    },
  },
})
