var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"jad-data-table jad-orders-table",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"orderItemId","dense":_vm.dense,"loading":_vm.loading,"disable-pagination":_vm.disablePagination,"hide-default-footer":_vm.hideDefaultFooter,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":50,"footer-props":{
    itemsPerPageOptions: [25, 50, 100, -1],
  }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":_vm.scrollToTop},scopedSlots:_vm._u([{key:"item.displayOrderId",fn:function(ref){
  var value = ref.value;
  var item = ref.item;
return [_c('router-link',{staticClass:"d-block text-no-wrap",attrs:{"to":{ name: 'order-edit', params: { orderId: item.orderId } }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.customer.abbr",fn:function(ref){
  var value = ref.value;
  var item = ref.item;
return [_c('router-link',{staticClass:"d-block text-truncate",staticStyle:{"max-width":"10em"},attrs:{"to":{
        name: 'customer',
        params: { customerId: item.customer.id },
      },"title":item.customer.name}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.product.abbr",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(value))]),(item.condition)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-avatar',_vm._g({staticStyle:{"margin":"-8px 4px","vertical-align":"baseline"},attrs:{"color":"primary","size":"16"}},on),[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(item.condition.abbr.substring(0, 1))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.condition.abbr))])]):_vm._e()]}},{key:"header.product.imageUrl",fn:function(ref){return [_c('v-icon',{attrs:{"small":""}},[_vm._v("$image")])]}},{key:"item.product.imageUrl",fn:function(ref){
      var value = ref.value;
return [_c('product-image',{attrs:{"value":value,"size":28}})]}},{key:"item.revenueAmount",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency))+" ")]}},{key:"item.netAmount",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency))+" ")]}},{key:"item.netUnitPrice",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency, { maximumFractionDigits: 4 }))+" ")]}},{key:"item.postedAmount",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency))+" ")]}},{key:"item.unpostedAmount",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value,item.currency))+" ")]}},{key:"item.orderNote",fn:function(ref){
      var value = ref.value;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"512px"},attrs:{"title":value},domProps:{"textContent":_vm._s(value)}})]}},{key:"item.orderItemNote",fn:function(ref){
      var value = ref.value;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"512px"},attrs:{"title":value},domProps:{"textContent":_vm._s(value)}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }