// utils
import { isDefault } from '@/utils/isDefault'

// types
type Operator = '=' | '<>' | '>' | '<' | '>=' | '<='
type SearchValue = string | number | boolean
interface TableSearchItemInput {
  field?: string
  operator: Operator
  value?: SearchValue
}

// defaults
const DEFAULT_TABLE_SEARCH_ITEM: TableSearchItemInput = {
  field: undefined,
  operator: '=',
  value: undefined,
}

export class TableSearchItem {
  field?: string
  operator: Operator
  value?: SearchValue

  constructor(input?: TableSearchItemInput) {
    if (!input) input = DEFAULT_TABLE_SEARCH_ITEM

    this.field = input.field
    this.operator = input.operator
    this.value = input.value
  }

  get isDefault(): boolean {
    return isDefault<TableSearchItem, TableSearchItemInput>(
      this,
      DEFAULT_TABLE_SEARCH_ITEM
    )
  }
}
