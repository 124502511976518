





































import Vue, { PropType } from 'vue'

// utils
import { isString } from '@/utils/type-helpers'

// types
import { Product } from '@/types'

export default Vue.extend({
  props: {
    value: [Object, String] as PropType<Product | string>,
    size: {
      type: [Number, String],
      default: 40,
    },
    width: Number,
    height: Number,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    imageUrl(): string | null {
      return isString(this.value)
        ? this.value
        : this.value
        ? this.value.imageUrl || null
        : null
    },
  },
})
