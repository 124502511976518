




import Vue, { PropType } from 'vue'

import {
  orderDetailFields,
  FieldReference,
  FieldReferenceLookup,
} from '@/components/orders/order-fields'

import { FilterValue } from '../explorer/FilterForm.vue'

const CALC_VALUE_ARRAY_JOINER = ' or '

export default Vue.extend({
  props: {
    field: String as PropType<keyof typeof orderDetailFields>,
    value: [String, Number, Array, Boolean] as PropType<FilterValue>,

    fields: {
      type: Object as PropType<FieldReferenceLookup>,
      default: () => orderDetailFields,
    },

    small: Boolean,
  },
  computed: {
    fieldArr(): string[] {
      return this.field.toString().split('!')
    },
    fieldName(): string {
      return this.fieldArr[0]
    },
    fieldReference(): FieldReference {
      return (
        this.fields[this.fieldName] || {
          label: this.fieldName,
          getter: () => this.value,
        }
      )
    },
    label(): string {
      return this.fieldReference.label
    },
    calcValue(): unknown {
      return (Array.isArray(this.value)
        ? this.value
        : this.value.toString().split('|')
      )
        .map(this.valueGetter)
        .join(CALC_VALUE_ARRAY_JOINER)
    },
    operator(): string {
      return this.fieldArr.length === 2 ? '<>' : '='
    },
  },
  methods: {
    valueGetter(v: string | number) {
      return v === 'null' ? '(NULL)' : this.fieldReference.getter(v)
    },
  },
})
