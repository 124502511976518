


































































import Vue from 'vue'
import log from '@/log'
import deepFreeze from 'deep-freeze'

// components
import OrderDetailsTable from '@/components/orders/OrderDetailsTable.vue'
import JTableSearch from '@/components/controls/JTableSearch.vue'
import JQueryChips from '@/components/controls/JQueryChips.vue'

// types
import { OrderDetail } from '@/types'

const DEFAULT_DENSE = true

export default Vue.extend({
  metaInfo: {
    title: 'Order Details',
  },
  components: {
    OrderDetailsTable,
    JTableSearch,
    JQueryChips,
  },
  props: {
    dense: {
      type: Boolean,
      default: DEFAULT_DENSE,
    },

    query: Object,
  },
  data: () => ({
    items: [] as ReadonlyArray<OrderDetail>,

    search: '',

    sortBy: ['receivedDate'],
    sortDesc: [true],

    isFetching: false,
    errors: [] as Error[],

    searchDialog: false,
    fields: [
      { field: 'id', name: 'ID', type: 'number' },
      { field: 'receivedDate', name: 'Received', type: 'date' },
      { field: 'netAmount', name: 'Net', type: 'number', format: 'currency' },
    ],

    disablePagination: false,
    hideDefaultFooter: false,
  }),
  computed: {},
  methods: {
    setItems(items: OrderDetail[]): void {
      this.items = deepFreeze(items)
    },
    refreshData(): void {
      this.fetchData()
    },
    onSearchInput(v: string): void {
      this.search = v
      // TODO: update window location on search input
    },
    fetchData(): void {
      this.isFetching = true
      this.errors = []

      const query = this.query

      delete query.q

      this.$api.orderDetails
        .list(query)
        .then(items => {
          this.setItems(items)
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    exportCsv() {
      const orderDetailsTable = this.$refs.orderDetailsTable as any // eslint-disable-line @typescript-eslint/no-explicit-any
      orderDetailsTable.exportCsv()
    },
  },
  mounted() {
    this.fetchData()
  },
})
