












































































































import Vue, { PropType } from 'vue'

import Order from '@/models/Orders/Order'

import ProductImage from '@/components/controls/ProductImage.vue'

import { exportCsv, ExportableDataTableHeader } from '@/utils/export-data-table'

const EXPORT_CSV_FILENAME = 'order_details'

export default Vue.extend({
  components: { ProductImage },
  props: {
    items: Array as PropType<Order[]>,
    search: String,

    loading: Boolean,
    dense: Boolean,

    disablePagination: Boolean,
    hideDefaultFooter: Boolean,
  },
  data: () => ({
    sortBy: ['postedDate'],
    sortDesc: [true],
  }),
  computed: {
    headers(): ExportableDataTableHeader[] {
      return [
        { text: 'Order ID', value: 'displayOrderId', exportType: 'text' },
        {
          text: 'Posted Date',
          value: 'postedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },

        { text: 'Company', value: 'company.abbr' },
        { text: 'Customer', value: 'customer.abbr' },
        { text: 'Revenue', value: 'revenueAmount', align: 'end' },

        { text: 'Qty', value: 'quantity', align: 'end' },
        {
          text: '',
          value: 'product.imageUrl',
          align: 'center',
          width: 0,
          sortable: false,
          hideInExport: true,
        },
        { text: 'Product', value: 'product.abbr', cellClass: 'text-truncate' },
        {
          text: 'Prod Cat',
          value: 'product.category.abbr',
          cellClass: 'text-truncate',
        },

        {
          text: 'Comm Comp',
          value: 'commissionComponent.abbr',
          cellClass: 'text-truncate',
        },
        {
          text: 'Comm Comp Grp',
          value: 'commissionComponent.group.abbr',
          cellClass: 'text-truncate',
        },

        {
          text: 'Pay Period',
          value: 'commissionPayPeriodAbbr',
          cellClass: 'text-truncate',
        },
        {
          text: 'Quota Period ',
          value: 'commissionQuotaPeriodAbbr',
          cellClass: 'text-truncate',
        },

        // TODO: show categories (TP, OTHER, SERVICE)
        {
          text: 'Quote No',
          value: 'quoteNo',
          width: '4em',
          cellClass: 'text-truncate',
          exportType: 'text',
        },

        { text: 'Net Amount', value: 'netAmount', align: 'end' },
        { text: 'Net Unit Price', value: 'netUnitPrice', align: 'end' },

        {
          text: 'Received Date',
          value: 'receivedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Processed Date',
          value: 'processedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Shipped Date',
          value: 'shippedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Est Shipped Date',
          value: 'estShippedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Delivered Date',
          value: 'deliveredDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Est Delivered Date',
          value: 'estDeliveredDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Installed Date',
          value: 'installedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Est Installed Date',
          value: 'estInstalledDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Est Posted Date',
          value: 'estPostedDate',
          align: 'center',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Ship Term',
          value: 'shippingTerm.abbr',
          align: 'start',
          cellClass: 'text-no-wrap',
        },

        {
          text: 'Order No',
          value: 'orderNo',
          cellClass: 'text-truncate',
          exportType: 'text',
        },
        {
          text: 'PO No',
          value: 'poNo',
          cellClass: 'text-truncate',
          exportType: 'text',
        },
        {
          text: 'Serial No',
          value: 'serialNo',
          cellClass: 'text-truncate',
          exportType: 'text',
        },

        {
          text: 'Order Type',
          value: 'orderType.abbr',
          cellClass: 'text-truncate',
        },
        {
          text: 'Order Category',
          value: 'orderCategory.abbr',
          cellClass: 'text-truncate',
        },

        // TODO: ItemCount

        { text: 'Posted Amount', value: 'postedAmount', align: 'end' },
        { text: 'Unposted Amount', value: 'unpostedAmount', align: 'end' },

        { text: 'Salesperson', value: 'salesperson.username' },
        { text: 'Sales Area', value: 'salesArea.abbr' },

        { text: 'Note', value: 'orderItemNote' },
        { text: 'Order Note', value: 'orderNote' },

        { text: 'Order Item ID', value: 'orderItemId' },
      ]
    },
  },
  methods: {
    exportCsv(): void {
      exportCsv(this.items, this.headers, EXPORT_CSV_FILENAME)
    },
    scrollToTop() {
      this.$vuetify.goTo('.jad-data-table tbody tr', {
        offset: 50,
        container: '.v-data-table__wrapper',
      })
    },
  },
})
