import store from '@/store'
import { getReferenceValue } from '@/store/utils'

type Getter = (value: string | number) => unknown

export type FieldReference = {
  label: string
  getter: Getter
  items?: () => unknown[]
  itemText?: string
  itemValue?: string
  nullable?: boolean
  notFilterable?: boolean
}

export type FieldReferenceLookup = {
  [field: string]: FieldReference
}

const defaultGetter: Getter = value => value

const labelOnly = <Item>(
  label: string,
  notFilterable = false
): FieldReference => ({
  label,
  getter: defaultGetter,
  notFilterable,
})

const itemsGetter = (getterName: string, text = 'abbr', value = 'id') => {
  return {
    items: () => store.getters[getterName],
    itemText: text,
    itemValue: value,
  }
}

const stringToBoolean = (value: string) => {
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true

    case 'false':
    case 'no':
    case '0':
    case null:
      return false

    default:
      return Boolean(value)
  }
}

const booleanGetter = (value: string | number): string => {
  if (typeof value === 'string') {
    return stringToBoolean(value).toString()
  } else {
    return (!!value).toString()
  }
}

const itemsBoolean = () => ({
  items: () => [
    { id: 1, label: 'true' },
    { id: 0, label: 'false' },
  ],
  itemText: 'label',
  itemValue: 'id',
})

export const orderDetailFields: FieldReferenceLookup = {
  companyId: {
    label: 'Company',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getCompanyById'),
    ...itemsGetter('companies'),
  },
  customerId: {
    label: 'Customer',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getCustomerById'),
    ...itemsGetter('customers'),
  },
  customerTypeId: {
    label: 'Customer Type',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getCustomerTypeById'),
    ...itemsGetter('customerTypes'),
  },
  shippingTermId: {
    label: 'Ship Term',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getShippingTermById'),
    ...itemsGetter('shippingTerms'),
  },
  salespersonId: {
    label: 'Salesperson',
    getter: value =>
      getReferenceValue(
        parseInt(value.toString()),
        'getSalespersonById',
        'username'
      ),
    ...itemsGetter('salespersons', 'username'),
  },
  salesAreaId: {
    label: 'Sales Area',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getSalesAreaById'),
    ...itemsGetter('salesAreas'),
  },
  commissionComponentId: {
    label: 'Commission Component',
    getter: value =>
      getReferenceValue(
        parseInt(value.toString()),
        'getCommissionComponentById'
      ),
    ...itemsGetter('commissionComponents'),
  },
  commissionComponentGroupId: {
    label: 'Commission Component Group',
    getter: value =>
      getReferenceValue(
        parseInt(value.toString()),
        'getCommissionComponentGroupById'
      ),
    ...itemsGetter('commissionComponentGroups'),
  },
  commissionQuotaPeriodId: {
    label: 'Commission Quota Period',
    getter: value =>
      getReferenceValue(
        parseInt(value.toString()),
        'getCommissionQuotaPeriodById'
      ),
    ...itemsGetter('commissionQuotaPeriods'),
  },
  commissionPayPeriodId: {
    label: 'Commission Pay Period',
    getter: value =>
      getReferenceValue(
        parseInt(value.toString()),
        'getCommissionPayPeriodById'
      ),
    ...itemsGetter('commissionPayPeriods'),
  },
  commissionPayPeriodPeriodGroup2: {
    label: 'Period Group 2 (Commission Pay Period) ',
    getter: value => value,
  },
  commissionPayPeriodPeriodGroup4: {
    label: 'Period Group 4 (Commission Pay Period) ',
    getter: value => value,
  },
  commissionPayPeriodPeriodNumber: {
    label: 'Period Number (Commission Pay Period) ',
    getter: value => value,
  },
  currency: {
    ...labelOnly('Currency'),
    ...itemsGetter('currencies', 'id', 'id'),
  },
  postedDate: labelOnly('Posted Date'),
  postedMonth: labelOnly('Posted Month'),
  postedYear: labelOnly('Posted Year'),
  postedYearMonth: labelOnly('Posted Year-Month'),
  estPostedDate: labelOnly('Est Posted Date'),
  estPostedMonth: labelOnly('Est Posted Month'),
  estPostedYear: labelOnly('Est Posted Year'),
  estPostedYearMonth: labelOnly('Est Posted Year-Month'),
  receivedDate: labelOnly('Received Date'),
  receivedMonth: labelOnly('Received Month'),
  receivedYear: labelOnly('Received Year'),
  receivedYearMonth: labelOnly('Received Year-Month'),
  processedDate: labelOnly('Processed Date'),
  processedMonth: labelOnly('Processed Month'),
  processedYear: labelOnly('Processed Year'),
  processedYearMonth: labelOnly('Processed Year-Month'),
  shippedDate: labelOnly('Shipped Date'),
  shippedMonth: labelOnly('Shipped Month'),
  shippedYear: labelOnly('Shipped Year'),
  shippedYearMonth: labelOnly('Shipped Year-Month'),
  estShippedDate: labelOnly('Est Shipped Date'),
  estShippedMonth: labelOnly('Est Shipped Month'),
  estShippedYear: labelOnly('Est Shipped Year'),
  estShippedYearMonth: labelOnly('Est Shipped Year-Month'),
  deliveredDate: labelOnly('Delivered Date'),
  deliveredMonth: labelOnly('Delivered Month'),
  deliveredYear: labelOnly('Delivered Year'),
  deliveredYearMonth: labelOnly('Delivered Year-Month'),
  estDeliveredDate: labelOnly('Est Delivered Date'),
  estDeliveredMonth: labelOnly('Est Delivered Month'),
  estDeliveredYear: labelOnly('Est Delivered Year'),
  estDeliveredYearMonth: labelOnly('Est Delivered Year-Month'),
  installedDate: labelOnly('Installed Date'),
  installedMonth: labelOnly('Installed Month'),
  installedYear: labelOnly('Installed Year'),
  installedYearMonth: labelOnly('Installed Year-Month'),
  estInstalledDate: labelOnly('Est Installed Date'),
  estInstalledMonth: labelOnly('Est Installed Month'),
  estInstalledYear: labelOnly('Est Installed Year'),
  estInstalledYearMonth: labelOnly('Est Installed Year-Month'),
  isPosted: {
    label: 'Is Posted',
    getter: booleanGetter,
    ...itemsBoolean(),
  },
  postedTrigger: {
    label: 'Posted Trigger',
    getter: defaultGetter,
    ...itemsGetter('postedTriggers'),
  },
  orderId: labelOnly('Order ID'),

  productId: {
    label: 'Product',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getProductById'),
    ...itemsGetter('products'),
  },
  productCategoryId: {
    label: 'Product Category',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getProductCategoryById'),
    ...itemsGetter('productCategories'),
  },
  conditionId: {
    label: 'Product Condition',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getProductConditionById'),
    ...itemsGetter('productConditions'),
    nullable: true,
  },

  quotaNo: labelOnly('Quota No'),
  orderNo: labelOnly('Order No'),
  poNo: labelOnly('PO No'),
  serialNo: labelOnly('Serial No'),

  orderTypeId: {
    label: 'Order Type',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getOrderTypeById'),
    ...itemsGetter('orderTypes'),
  },
  orderCategoryId: {
    label: 'Order Category',
    getter: value =>
      getReferenceValue(parseInt(value.toString()), 'getOrderCategoryById'),
    ...itemsGetter('orderCategories'),
  },

  hasDeliveryAddress: {
    label: 'Has Delivery Address',
    getter: booleanGetter,
    ...itemsBoolean(),
  },
  deliveryAddressId: {
    label: 'Delivery Address',
    getter: value =>
      (
        store.state.customers
          .find(c => c.addresses.some(a => a.id === value))
          ?.addresses.find(a => a.id === value) || { displayAddress: value }
      ).displayAddress,
    nullable: true,
  },
  country: labelOnly('Country'),
  state: labelOnly('State'),
  county: labelOnly('County'),
  city: labelOnly('City'),
  zip: labelOnly('Zip'),
  countyStateCountry: labelOnly('County (Full)', true),
  cityStateCountry: labelOnly('City (Full)', true),
}
