import store from '@/store'

export const getReferenceValue = <T>(
  value: unknown,
  getter: keyof typeof store.getters,
  prop = 'abbr'
) => {
  const obj = store.getters[getter](value)
  if (obj && Object.prototype.hasOwnProperty.call(obj, prop)) return obj[prop]
  return value
}
